
import AbpBase from '@/lib/abpbase'
import { Component, Vue, Inject, Prop, Watch } from 'vue-property-decorator'
import Cliente from '@/store/entities/OZONE/cliente'
import PageRequest from '@/store/entities/page-request'
class PageClienteRequest extends PageRequest {
  nombre: string
}
@Component
export default class SelectCliente extends AbpBase {
  @Prop({ type: Object, default: null }) value: Cliente
  @Prop({ type: Boolean, default: true }) showPagination: Boolean
  @Prop({ type: Object, default: null }) rules: Object
  @Prop({ type: Boolean, default: false }) disabled: Boolean
  @Prop({ type: String, default: 'Cliente' }) name: String
  @Prop({ type: String, default: 'Cliente'}) label: String
  cliente: Cliente = null
  pagerequestClientes: PageClienteRequest = new PageClienteRequest()
  maxResultCountCliente = 10
  skipCountCliente = 0
  get clienteList() {
    return this.$store.state.cliente.list
  }
  get clienteCount() {
    return this.$store.state.cliente.totalCount
  }

  async created() {
    this.getClientesDropDown()
  }


  select(event){
    this.$store.commit('cliente/select', event)
    this.$emit('select', event);
  }

  async getClientes(params: any) {
    this.pagerequestClientes.nombre = params
    this.maxResultCountCliente = 50
    this.skipCountCliente = 0
    this.pagerequestClientes.maxResultCount = this.maxResultCountCliente
    this.pagerequestClientes.skipCount = this.skipCountCliente
    await this.$store.dispatch({
      type: 'cliente/getAll',
      data: this.pagerequestClientes
    })
  }

  async nextPage(offset: number) {
    this.pagerequestClientes.skipCount = offset
    await this.$store.dispatch({
      type: 'cliente/getAll',
      data: this.pagerequestClientes
    })
  }

  async getClientesDropDown() {
    this.pagerequestClientes.maxResultCount = 100;
    this.pagerequestClientes.skipCount = 0;
    await this.$store.dispatch({
      type: 'cliente/getAll',
      data: this.pagerequestClientes
    })
  }

  clienteRule = {
    cliente: {
      // required: { required: this.required },
      message: this.L('ThisFieldIsRequired', undefined, this.L('Cliente')),
      placeholder: this.L('Cliente'),
      trigger: 'blur'
    }
  }
}
